const initState = {
    authError: null,
    resetMessage: null
};
const authReducer = (state = initState, action) => {
    switch (action.type) {
        case 'LOGIN_ERROR':
            return {
                ...state,
                authError: action.err.message
            };
        case 'LOGIN_SUCCESS':
            return {
                ...state,
                authError: null
            };
        case 'SIGNOUT_SUCCESS':
            return state;
        case 'SIGNUP_SUCCESS':
            return {
                ...state,
                authError: null,
            };
        case 'SIGNUP_ERROR':
            return {
                ...state,
                authError: action.err.message
            };
        case 'RESET_SUCCESS':
            return {
                ...state,
                resetMessage: 'Tarkista sähköpostisi hetken kuluttua.'
            };
        default:
            return state;
    }
};

export default authReducer;