import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import IconButton from "@material-ui/core/IconButton";

const SimpleMenu = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = e => setAnchorEl(e.currentTarget);

    const handleClose = () => setAnchorEl(null);

    return <div>
        <IconButton edge="end" aria-label="Koneet" size={"small"}
                    onClick={handleClick}>
            <FontAwesomeIcon icon="ellipsis-v"/>
        </IconButton>
        <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
            <MenuItem
                onClick={() => props.updateRef.current.handleOpen(props.item) && handleClose}>Muokkaa</MenuItem>
            <MenuItem onClick={() => props.deleteFunc(props.id) && handleClose}>Poista</MenuItem>
        </Menu>
    </div>;
};

export default SimpleMenu;