import React from "react";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import Grid from "@material-ui/core/Grid";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import SimpleMenu from "../layout/SimpleMenu";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import {connect} from "react-redux";
import {compose} from "redux";
import {firestoreConnect} from "react-redux-firebase";
import CreateStatement from "./CreateStatement";
import {deleteStatementType} from "../../store/actions/statementActions";
import UpdateStatement from "./UpdateStatement";

const ListStatements = (props) => {

    const createRef = React.useRef();
    const updateRef = React.useRef();

    return <Paper className={props.classes.paper}>
        <CreateStatement ref={createRef}/>
        <UpdateStatement ref={updateRef}/>
        <List
            component="nav"
            subheader={<ListSubheader component="div">
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                >
                    Selvitystyypit
                    {props.profile && (props.profile.role === "master") ?
                        <IconButton edge="end" aria-label="Lisää selvitys" size={"small"}
                                    onClick={() => createRef.current.handleOpen()}>
                            <FontAwesomeIcon icon="plus"/>
                        </IconButton> : null}
                </Grid>
            </ListSubheader>}
        >
            {props.statement_types && props.statement_types.map(type => {
                return <ListItem button key={type.id}>
                    <ListItemIcon>
                        <FontAwesomeIcon icon='comment-alt-dots'/>
                    </ListItemIcon>
                    <ListItemText primary={type.name}/>
                    <ListItemSecondaryAction>
                        <SimpleMenu id={type.id} deleteFunc={() => props.deleteStatementType(type.id)}
                                    updateRef={updateRef} item={type}/>
                    </ListItemSecondaryAction>
                </ListItem>;
            })}
        </List>
    </Paper>;
};

export const buildStatementTypes = (types, profile, sites) => {
    const typeArray = [];
    types && types.map(type => {
        if (sites && profile.role === "master") {
            if (sites.find(site => site.id === profile.auth.uid)) {
                return typeArray.push(type);
            } else return null;
        } else {
            return typeArray.push(type);
        }
    });
    return typeArray;
};

const mapStateToProps = (state) => {
    const sites = state.firestore.sites;
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        machines: state.firestore.ordered.machineList,
        statement_types: buildStatementTypes(state.firestore.ordered.statement_types_list, state.firebase.profile, sites)
    }
};

const mapDispatchToProps = (dispatch) => ({
    deleteStatementType: (id) => dispatch(deleteStatementType(id))
});
export default compose(connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true}), firestoreConnect((props) => {
    const query = [
        ['deleted', '==', false],
        ['company', '==', props.profile.company]
    ];

    return [{
        collection: 'statement_types',
        where: query,
        storeAs: 'statement_types_list'
    }]
}))(ListStatements)
