import React, {useImperativeHandle} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {createSite} from "../../store/actions/siteActions";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        marginTop: theme.spacing(1),
        width: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
});


const CreateSite = React.forwardRef((props, ref) => {

    const [open, setOpen] = React.useState(false);
    const [name, setName] = React.useState("");
    const [address, setAddress] = React.useState("");
    const [postalCode, setPostalCode] = React.useState("");
    const [city, setCity] = React.useState("");
    const [country] = React.useState("fi");
    const [rerender, setRerender] = React.useState(false);
    const [owners, setOwners] = React.useState([]);

    useImperativeHandle(ref, () => ({
        handleOpen() {
            setOpen(true);
        }
    }));

    const handleClose = () => {
        setOpen(false)
    };

    const handleSave = (e) => {
        e.preventDefault();
        props.createSite({name, address, postalCode, city, country, owners});
        handleClose();
    };

    const handleOwner = (owner) => {
        let tempOwners = owners;
        if (tempOwners.includes(owner)) {
            tempOwners = tempOwners.filter(value => value !== owner);
        } else {
            tempOwners.push(owner);
        }
        setOwners(tempOwners);
        setRerender(!rerender);
    };

    return <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={'sm'}
    >
        <DialogTitle id="form-dialog-title">Lisää uusi työmaa</DialogTitle>
        <DialogContent>
            <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Työmaan nimi"
                type="text"
                fullWidth
                value={name}
                onChange={event => setName(event.target.value)}
            />
            <TextField
                margin="dense"
                id="address"
                label="Katuosoite"
                type="text"
                fullWidth
                value={address}
                onChange={event => setAddress(event.target.value)}
            />
            <TextField
                margin="dense"
                id="postalCode"
                label="Postinumero"
                type="text"
                fullWidth
                value={postalCode}
                onChange={event => setPostalCode(event.target.value)}
            />
            <TextField
                margin="dense"
                id="city"
                label="Kaupunki"
                type="text"
                fullWidth
                value={city}
                onChange={event => setCity(event.target.value)}
            />
            {props.profile && props.profile.role !== "master" &&
            <Box mt={2}>
                <Typography variant="body2">
                    Valitse mestarit
                </Typography>
                {props.masters &&
                <FormGroup>
                    {props.masters.map(master =>
                        <FormControlLabel
                            control={<Checkbox
                                checked={owners.includes(master.id)}
                                onChange={() => handleOwner(master.id)}
                                value={master.id}/>}
                            label={master.name.first + " " + master.name.last}
                            key={master.id}
                        />
                    )}
                </FormGroup>
                }
            </Box>}
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="primary">
                Sulje
            </Button>
            <Button onClick={handleSave} color="primary">
                Lisää
            </Button>
        </DialogActions>
    </Dialog>;
});

const mapStateToProps = (state) => ({
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    masters: state.firestore.ordered.masters,
});
const mapDispatchToProps = (dispatch) => ({
    createSite: (site) => dispatch(createSite(site))
});
export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(withStyles(styles)(CreateSite));
