import React, {useImperativeHandle, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {connect} from "react-redux";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import {makeStyles} from "@material-ui/core";
import {createStatementType} from "../../store/actions/statementActions";

const useStyles = makeStyles(theme => ({
    formControl: {
        marginTop: theme.spacing(1),
        width: '100%',
    },
}));

const CreateStatement = React.forwardRef((props, ref) => {

    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const [statement, setStatement] = useState("");
    const [site, setSite] = useState("");

    const handleClose = (e) => {
        e.preventDefault();
        setOpen(false);
    };

    useImperativeHandle(ref, () => ({
        handleOpen() {
            setOpen(true);
        }
    }));

    const handleSave = (e) => {
        e.preventDefault();
        props.createStatementType({name: statement, site});
        handleClose(e);
    };

    return <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={'sm'}
    >
        <DialogTitle id="form-dialog-title">Lisää uusi selvitystyyppi</DialogTitle>
        <DialogContent>
            <TextField
                autoFocus
                margin="dense"
                id="statement"
                label="Selvitystyyppi"
                type="text"
                fullWidth
                value={statement}
                onChange={event => setStatement(event.target.value)}
            />
            <FormControl className={classes.formControl}>
                <InputLabel htmlFor="site">Työmaa</InputLabel>
                <Select
                    value={site}
                    onChange={event => setSite(event.target.value)}
                    inputProps={{
                        name: 'site',
                        id: 'site',
                    }}
                >
                    {props.sites && props.sites.map(site => {
                        return (<MenuItem value={site.id} key={site.id}>{site.name}</MenuItem>);
                    })}
                </Select>
            </FormControl>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="primary">
                Sulje
            </Button>
            <Button onClick={handleSave} color="primary">
                Lisää
            </Button>
        </DialogActions>
    </Dialog>
});


const mapStateToProps = (state) => ({
    sites: state.firestore.ordered.siteList && state.firestore.ordered.siteList.filter(site => state.firebase.profile.role !== "master" || site.owners.includes(state.firebase.auth.uid))
});

const mapDispatchToProps = (dispatch) => ({
    createStatementType: (statement) => dispatch(createStatementType(statement))
});

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(CreateStatement);
