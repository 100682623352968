import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const config = {
    apiKey: "AIzaSyAL74tuEdKGO5TfjTamPy5mLLV3VlI38ro",
    authDomain: "konelappu.firebaseapp.com",
    databaseURL: "https://konelappu.firebaseio.com",
    projectId: "konelappu",
    storageBucket: "konelappu.appspot.com",
    messagingSenderId: "878964139948",
    appId: "1:878964139948:web:099005012925872d",
};
firebase.initializeApp(config);
firebase.firestore();

export default firebase;