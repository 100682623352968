import React, {forwardRef, useRef} from "react";
import {firestoreConnect, isEmpty, populate} from "react-redux-firebase";
import {compose} from "redux";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core";
import moment from "moment";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import MaterialTable from "material-table";
import Add from '@material-ui/icons/Add';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CreateFill from "./CreateFill";
import {checkFill, deleteFill} from "../../store/actions/fillActions";
import Button from "@material-ui/core/Button";
import ExportFill from "./ExportFill";
import UpdateFill from "./UpdateFill";

const tableIcons = {
    Add: forwardRef((props, ref) => <Add {...props} ref={ref}/>),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref}/>),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}/>),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref}/>),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}/>),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}/>)
};

const styles = theme => ({
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        boxShadow: "0 1rem 3rem rgba(0,0,0,.175)"
    },
});

const ListFills = (props) => {

    const childRef = useRef();
    const exportRef = useRef();
    const updateRef = useRef();
    const columns = [
        {
            field: "date",
            title: "Päiväys",
            customSort: (a, b) => a.dateSeconds - b.dateSeconds,
        },
        {
            field: "machine",
            title: "Työkone",
        },
        {
            field: "statement",
            title: "Selvitys",
        },
        {
            field: "workHours",
            title: "Työaika",
        },
        {
            field: "site",
            title: "Työmaa",
        },
    ];
    if (props.profile && props.profile.role !== "worker") {
        columns.push({field: "user", title: "Työntekijä"});
    }
    columns.push({field: "checkedString", title: "Tarkistettu", defaultSort: 'asc'});


    const localization = {
        body: {
            emptyDataSourceMessage: 'Ei tuloksia',
            addTooltip: 'Lisää',
            deleteTooltip: 'Poista',
            editTooltip: 'Muokkaa',
            filterRow: {
                filterTooltip: 'Suodata'
            },
            editRow: {
                deleteText: 'Poista',
                cancelTooltip: 'Peruuta',
                saveTooltip: 'Tallenna',
            }
        },
        grouping: {
            placeholder: 'Raahaa placeholdereita'
        },
        header: {
            actions: 'Toiminnot'
        },
        pagination: {
            labelDisplayedRows: '{from}-{to} / {count}',
            labelRowsSelect: ' rivi(ä)',
            firstAriaLabel: 'Ensimmäinen sivu',
            firstTooltip: 'Ensimmäinen sivu',
            previousAriaLabel: 'Edellinen sivu',
            previousTooltip: 'Edellinen sivu',
            nextAriaLabel: 'Seuraava sivu',
            nextTooltip: 'Seuraava sivu',
            lastAriaLabel: 'Viimeinen sivu',
            lastTooltip: 'Viimeinen sivu',
        },
        toolbar: {
            addRemoveColumns: 'Lisää tai poista sarakkeita',
            nRowsSelected: '{0} rivi(ä) valittu',
            showColumnsTitle: 'Näytä sarakkeita',
            showColumnsAriaLabel: 'Näytä sarakkeita',
            exportTitle: 'Vie',
            exportAriaLabel: 'Vie',
            exportName: 'Vie CSV:nä',
            searchTooltip: 'Hae täytöistä...',
            searchPlaceholder: 'Hae täytöistä...',
        }
    };

    const [currentFill, setCurrentFill] = React.useState({});
    return props.fills && props.profile ?
        <Box>
            <CreateFill ref={childRef}/>
            <ExportFill ref={exportRef}/>
            <UpdateFill ref={updateRef} fill={currentFill}/>
            {isEmpty(props.fills) ?
                <Paper className={props.classes.paper}>
                    <Box style={{display: 'flex', justifyContent: 'center', paddingBottom: 20, paddingTop: 20}}
                         width="100%">
                        <Box style={{display: 'block'}}>
                            <p style={{textAlign: 'center'}}>Ei vielä täyttöjä</p>
                            {props.profile.role === "admin" || props.profile.role === "worker" ?
                                <Button variant="contained" color="primary"
                                        onClick={() => childRef.current.handleOpen()}>
                                    Lisää uusi täyttö
                                </Button> : null
                            }
                        </Box>
                    </Box>
                </Paper>
                :
                <MaterialTable
                    title="Täytöt"
                    columns={columns}
                    data={props.fills ? props.fills : []}
                    options={{
                        sorting: true,
                        actionsColumnIndex: -1,
                        exportButton: false
                    }}
                    localization={localization}
                    actions={[
                        {
                            icon: 'save_alt',
                            tooltip: 'Lataa PDF-tiedostona',
                            isFreeAction: true,
                            onClick: () => exportRef.current.handleOpen(),
                            hidden: props.profile.role !== "accountant"

                        },
                        {
                            icon: 'add',
                            tooltip: 'Lisää täyttö',
                            isFreeAction: true,
                            onClick: () => childRef.current.handleOpen(),
                            hidden: props.profile.role !== "worker" && props.profile.role !== "admin"
                        },
                        rowData => (
                            {
                                icon: 'edit',
                                tooltip: rowData.checked ? "Et voi muokata tarkistettua täyttöä" : "Muokkaa täyttöä",
                                onClick: (event, rowData) => {
                                    setCurrentFill(rowData);
                                    updateRef.current.handleOpen()
                                },
                                hidden: props.profile.role === "accountant",
                                disabled: rowData.checked
                            }
                        ),
                        rowData => (
                            {
                                icon: 'check',
                                tooltip: rowData.checked ? "Tarkistettu" : "Merkitse tarkistetuksi",
                                onClick: (event, rowData) => props.checkFill(rowData.id),
                                hidden: rowData.checked || props.profile.role !== "master"
                            }
                        ),
                        rowData => (
                            {
                                icon: 'delete',
                                tooltip: rowData.checked ? "Et voi poistaa tarkistettua täyttöä" : "Poista täyttö",
                                onClick: (event, rowData) => {
                                    props.deleteFill(rowData.id);
                                },
                                hidden: props.profile.role === "master" || props.profile.role === "accountant",
                                disabled: rowData.checked
                            }
                        )
                    ]}
                    icons={tableIcons}
                />
            }
        </Box> : <Paper>
            <Box style={{display: 'flex', justifyContent: 'center', paddingBottom: 20, paddingTop: 20}}
                 width="100%">
                <CircularProgress/>
            </Box>
        </Paper>;
};

const populates = [
    {child: 'machine', root: 'machines'},
    {child: 'site', root: 'sites'},
    {child: 'user', root: 'users'},
    {child: 'master', root: 'users'}
];

const buildData = (fills, state, sites) => {
    let built = [];
    fills && Object.keys(fills).map(fill => {
        if (state.firebase.profile.role === "master" && fills[fill].site === "") return null;
        if (state.firebase.profile.role === "master" && !fills[fill].site.owners.includes(state.firebase.auth.uid)) return null;
        if (state.firebase.profile.role === "worker" && fills[fill].user.id !== state.firebase.auth.uid) return null;
        return built.push({
            ...fills[fill],
            id: fill,
            machine: fills[fill].machine.name ? fills[fill].machine.name : "",
            machineId: fills[fill].machine.id ? fills[fill].machine.id : "",
            site: fills[fill].site.name ? fills[fill].site.name : "",
            siteId: fills[fill].site.id ? fills[fill].site.id : "",
            user: fills[fill].user.name ? fills[fill].user.name.first + " " + fills[fill].user.name.last : "",
            master: fills[fill].master.id ? fills[fill].master.name.first + " " + fills[fill].master.name.last : "",
            masterId: fills[fill].master.id ? fills[fill].master.id : "",
            date: moment(fills[fill].date).format('DD.MM.YYYY'),
            dateSeconds: fills[fill].date,
            checkedString: fills[fill].checked ? 'Kyllä' : 'Ei',
            workHours: fills[fill].startTime && fills[fill].endTime ? fills[fill].startTime + " - " + fills[fill].endTime
                + " (" + fills[fill].hours + "h)" : fills[fill].hours + "h"
        });
    });
    return built;
};

const mapStateToProps = state => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        fills: buildData(populate(state.firestore, 'fills', populates), state),

    }
};
const mapDispatchToProps = dispatch => {
    return {
        checkFill: fill => dispatch(checkFill(fill)),
        deleteFill: fill_id => dispatch(deleteFill(fill_id))
    }
};

export default compose(connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true}), firestoreConnect(props => {
    const query = [
        ['deleted', '==', false],
        ['company', '==', props.profile.company]
    ];

    if (props.profile.role === "worker") query.push(['user', '==', props.auth.uid]);
    return [{
        collection: 'fills',
        orderBy: ['date', 'desc'],
        where: query,
        populates,
    }]
}))(withStyles(styles, {withTheme: true})(ListFills))
