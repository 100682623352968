import React, {useImperativeHandle} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {connect} from "react-redux";
import {inviteUser} from "../../store/actions/userActions";
import Grid from "@material-ui/core/Grid";


const CreateMaster = React.forwardRef((props, ref) => {
    const [open, setOpen] = React.useState(false);
    const [email, setEmail] = React.useState("");
    const [role] = React.useState("master");
    const [name, setName] = React.useState({
        first: "",
        last: ""
    });

    useImperativeHandle(ref, () => ({
        handleOpen() {
            setOpen(true);
        }
    }));

    const handleClose = () => setOpen(false);
    const handleSave = () => {
        props.inviteUser({email, role, name});
        handleClose();
    };

    return <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={'sm'}
    >
        <DialogTitle id="form-dialog-title">Kutsu uusi työmaamestari</DialogTitle>
        <DialogContent>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="firstName"
                        variant="outlined"
                        required
                        fullWidth
                        id="firstName"
                        label="Etunimi"
                        autoFocus
                        value={name.first}
                        onChange={event => setName({
                            first: event.target.value,
                            last: name.last
                        })}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="lastName"
                        label="Sukunimi"
                        name="lastName"
                        value={name.last}
                        onChange={event => setName({
                            first: name.first,
                            last: event.target.value
                        })}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="email"
                        label="Sähköpostiosoite"
                        type="email"
                        value={email}
                        onChange={event => setEmail(event.target.value)}
                    />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="primary">
                Sulje
            </Button>
            <Button onClick={handleSave} color="primary">
                Kutsu
            </Button>
        </DialogActions>
    </Dialog>;
});

const mapDispatchToProps = (dispatch) => {
    return {
        inviteUser: (master) => dispatch(inviteUser(master))
    }
};
export default connect(null, mapDispatchToProps, null, {forwardRef: true})(CreateMaster);