import moment from "moment";

export const createFill = (fill) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();
        delete fill.open;
        firestore.collection('fills').add({
            ...fill,
            date: moment(fill.date).valueOf(),
            company: getState().firebase.profile.company,
            user: getState().firebase.auth.uid,
            createdAt: firestore.FieldValue.serverTimestamp(),
            deleted: false
        })
            .then(() => {
                dispatch({type: 'CREATE_FILL', fill});
            })
            .catch((err) => {
                dispatch({type: 'CREATE_FILL_ERROR', err});
            });
    }
};

export const updateFill = (fill, id) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();
        firestore.collection('fills').doc(id).get().then(fillData => {
            if (!fillData.exists) return dispatch({
                type: 'UPDATE_FILL_ERROR',
                'err': {message: 'Täyttöä ei löytynyt!'}
            });
            firestore.collection('fills').doc(id).set({...fillData.data(), ...fill}).then(() => {
                dispatch({type: 'UPDATE_FILL', fillData});
            }).catch(err => {
                dispatch({type: 'UPDATE_FILL_ERROR', err});
                console.log(err)
            });
        }).catch(err => {
            dispatch({type: 'UPDATE_FILL_ERROR', err});
        });

    }
};

export const deleteFill = fill_id => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();

        firestore.collection('fills').doc(fill_id).get().then(fill => {
            if (!fill.exists) return dispatch({type: 'DELETE_FILL_ERROR', 'err': {message: 'Täyttöä ei löytynyt!'}});
            firestore.collection('fills').doc(fill_id).delete().then(() => dispatch({type: 'DELETE_FILL'})).catch(err => dispatch({
                type: 'DELETE_FILL_ERROR',
                err
            }))
        });
    }
};

export const checkFill = (id) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();
        firestore.collection('fills').doc(id).get().then(fill => {
            if (!fill.exists) return dispatch({type: 'CHECK_FILL_ERROR', 'err': {message: 'Täyttöä ei löytynyt!'}});
            firestore.collection('fills').doc(id).set({...fill.data(), checked: true}).then(() => {
                dispatch({type: 'CHECK_FILL', fill});
            }).catch(err => {
                dispatch({type: 'CHECK_FILL_ERROR', err});
            });
        }).catch(err => {
            dispatch({type: 'CHECK_FILL_ERROR', err});
        });

    }
};
