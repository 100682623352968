export const createSite = (site) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();
        delete site.open;
        firestore.collection('sites').add({
            ...site,
            company: getState().firebase.profile.company,
            createdAt: firestore.FieldValue.serverTimestamp(),
            deleted: false
        })
            .then(() => {
                dispatch({type: 'CREATE_SITE', site});
            })
            .catch((err) => {
                dispatch({type: 'CREATE_SITE_ERROR', err});
            });
    }
};

export const updateSite = (site, id) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();
        firestore.collection('sites').doc(id).get().then(siteDoc => {
            if (!siteDoc.exists) return dispatch({
                type: 'UPDATE_SITE_ERROR',
                'err': {message: 'Työmaata ei löytynyt!'}
            });
            firestore.collection('sites').doc(id).set({
                ...siteDoc.data(),
                ...site
            }).then(() => {
                dispatch({type: 'UPDATE_SITE', site});
            }).catch(err => {
                dispatch({type: 'UPDATE_SITE_ERROR', err});
            });
        }).catch(err => {
            dispatch({type: 'UPDATE_SITE_ERROR', err});
        });
    }
};

export const deleteSite = (id) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();
        firestore.collection('sites').doc(id).get().then(site => {
            if (!site.exists) return dispatch({type: 'DELETE_SITE_ERROR', 'err': {message: 'Työmaata ei löytynyt!'}});
            firestore.collection('sites').doc(id).set({...site.data(), deleted: true}).then(() => {
                dispatch({type: 'DELETE_SITE', site});
            }).catch(err => {
                dispatch({type: 'DELETE_SITE_ERROR', err});
                console.log(err)
            });
        }).catch(err => {
            dispatch({type: 'DELETE_SITE_ERROR', err});
            console.log(err)
        });
    }
};
