export const signIn = (credentials) => {
    return (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase();
        firebase.auth().signInWithEmailAndPassword(credentials.email, credentials.password).then(() => {
            dispatch({type: 'LOGIN_SUCCESS'});
        }).catch((err) => {
            dispatch({type: 'LOGIN_ERROR', err});
        });
    }
};

export const signUp = (user) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firebase = getFirebase();
        const firestore = getFirestore();

        firestore.collection('invitations').doc(user.invitation).get().then(doc => {
            if (!doc.exists) return dispatch({type: 'SIGNUP_ERROR', 'err': {message: 'Virheellinen kutsuntatunnus'}});
            if (doc.data().email !== user.email) return dispatch({
                type: 'SIGNUP_ERROR',
                'err': {message: 'Tätä sähköpostiosoitetta ei ole kutsuttu!'}
            });
            firebase.auth().createUserWithEmailAndPassword(user.email, user.password).then((res) => {
                return firestore.collection('users').doc(res.user.uid).set({
                    name: {
                        first: user.firstname,
                        last: user.lastname
                    },
                    invitation: user.invitation,
                    createdAt: firestore.FieldValue.serverTimestamp()
                }).then(() => {
                    dispatch({type: 'SIGNUP_SUCCESS'});
                }).catch((error) => {
                    dispatch({type: 'SIGNUP_ERROR', error})
                })
            })
        }).catch(err => dispatch({type: 'SIGNUP_ERROR', err}));
    }
};

export const signOut = () => {
    return (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase();
        firebase.auth().signOut().then(() => {
            dispatch({type: 'SIGNOUT_SUCCESS'});
        });
    }
};

export const resetPassword = (email) => {
    return (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase();
        firebase.auth().sendPasswordResetEmail(email).then(() => {
            dispatch({type: 'RESET_SUCCESS'});
        }).catch(err => dispatch({type: 'RESET_ERROR', err}));
    }
};

