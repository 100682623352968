import React from "react";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import Grid from "@material-ui/core/Grid";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import SimpleMenu from "../layout/SimpleMenu";
import Paper from "@material-ui/core/Paper";
import CreateMachine from "./CreateMachine";
import IconButton from "@material-ui/core/IconButton";
import {connect} from "react-redux";
import {compose} from "redux";
import {firestoreConnect} from "react-redux-firebase";
import {deleteMachine} from "../../store/actions/machineActions";
import UpdateMachine from "./UpdateMachine";

const ListMachines = (props) => {

    const createRef = React.useRef();
    const updateRef = React.useRef();

    return <Paper className={props.classes.paper}>
        <CreateMachine ref={createRef}/>
        <UpdateMachine ref={updateRef}/>
        <List
            component="nav"
            subheader={<ListSubheader component="div">
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                >
                    Työkoneet
                    {props.profile && (props.profile.role === "admin" || props.profile.role === "worker") ?
                        <IconButton edge="end" aria-label="Lisää kone" size={"small"}
                                    onClick={() => createRef.current.handleOpen()}>
                            <FontAwesomeIcon icon="plus"/>
                        </IconButton> : null}
                </Grid>
            </ListSubheader>}
        >
            {props.machines && props.machines.map(machine => {
                return <ListItem button key={machine.id}>
                    <ListItemIcon>
                        <FontAwesomeIcon icon='truck-plow'/>
                    </ListItemIcon>
                    <ListItemText primary={machine.name}/>
                    <ListItemSecondaryAction>
                        <SimpleMenu id={machine.id} deleteFunc={() => props.deleteMachine(machine.id)}
                                    updateRef={updateRef} item={machine}/>
                    </ListItemSecondaryAction>
                </ListItem>;
            })}
        </List>
    </Paper>;
};

const mapStateToProps = (state) => {
    return {
        profile: state.firebase.profile,
        machines: state.firestore.ordered.machineList
    }

};

const mapDispatchToProp = (dispatch) => {
    return {
        deleteMachine: (machine) => dispatch(deleteMachine(machine))
    }
};
export default compose(connect(mapStateToProps, mapDispatchToProp, null, {forwardRef: true}), firestoreConnect(props => [{
    collection: 'machines',
    where: [
        ['deleted', '==', false],
        ['company', '==', props.profile.company]
    ],
    storeAs: 'machineList'
}]))(ListMachines)