const initState = {
    fills: []
};
const fillsReducer = (state = initState, action) => {
    switch (action.type) {
        case 'DELETE_FILL':
            return state;
        case 'DELETE_FILL_ERROR':
            return state;
        default:
            return state;
    }
};

export default fillsReducer;