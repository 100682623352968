export const createMachine = (machine) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();
        delete machine.open;
        firestore.collection('machines').add({
            ...machine,
            company: getState().firebase.profile.company,
            createdAt: firestore.FieldValue.serverTimestamp(),
            deleted: false
        })
            .then(() => {
                dispatch({type: 'CREATE_MACHINE', machine});
            })
            .catch((err) => {
                dispatch({type: 'CREATE_MACHINE_ERROR', err});
            });
    }
};

export const updateMachine = (id, machine) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();
        firestore.collection('machines').doc(id).get().then(machineDoc => {
            if (!machineDoc.exists) return dispatch({
                type: 'UPDATE_MACHINE_ERROR',
                'err': {message: 'Työkonetta ei löytynyt!'}
            });
            firestore.collection('machines').doc(id).set({
                ...machineDoc.data(),
                name: machine.name,
                registerNumber: machine.registerNumber
            }).then(() => {
                dispatch({type: 'UPDATE_MACHINE', machine});
            }).catch(err => {
                dispatch({type: 'UPDATE_MACHINE_ERROR', err});
            });
        }).catch(err => {
            dispatch({type: 'UPDATE_MACHINE_ERROR', err});
        });
    }
};

export const deleteMachine = (id) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();
        firestore.collection('machines').doc(id).get().then(machine => {
            if (!machine.exists) return dispatch({
                type: 'DELETE_MACHINE_ERROR',
                'err': {message: 'Työkonetta ei löytynyt!'}
            });
            firestore.collection('machines').doc(id).set({...machine.data(), deleted: true}).then(() => {
                dispatch({type: 'DELETE_MACHINE', machine});
            }).catch(err => {
                dispatch({type: 'DELETE_MACHINE_ERROR', err});
            });
        }).catch(err => {
            dispatch({type: 'DELETE_MACHINE_ERROR', err});
        });
    }
};