const initState = {};
const machineReducer = (state = initState, action) => {
    switch (action.type) {
        case 'CREATE_MACHINE':
            return state;
        case 'UPDATE_MACHINE':
            return state;
        case 'DELETE_MACHINE':
            return state;
        case 'CREATE_MACHINE_ERROR':
            console.error('Error happened while creating a machine', action.err);
            return state;
        case 'UPDATE_MACHINE_ERROR':
            console.error('Error happened while updating an existing machine', action.err);
            return state;
        case 'DELETE_MACHINE_ERROR':
            console.error('Error happened while deleting an existing machine', action.err);
            return state;
        default:
            return state;
    }
};

export default machineReducer;