import React, {useImperativeHandle} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {connect} from "react-redux";
import {updateStatementType} from "../../store/actions/statementActions";
import {makeStyles} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles(theme => ({
    formControl: {
        marginTop: theme.spacing(1),
        width: '100%',
    },
}));

const UpdateStatement = React.forwardRef((props, ref) => {

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [id, setId] = React.useState("");
    const [name, setName] = React.useState("");
    const [site, setSite] = React.useState("");

    useImperativeHandle(ref, () => ({
        handleOpen(item) {
            setOpen(true);
            setId(item.id);
            setName(item.name);
            setSite(item.site);
        }
    }));

    const handleClose = () => setOpen(false);
    const handleUpdate = () => {
        props.updateStatementType({name, site}, id);
        handleClose()
    };

    return <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={"sm"}
    >
        <DialogTitle id="form-dialog-title">Päivitä selvitystyyppiä</DialogTitle>
        <DialogContent>
            <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Nimi"
                type="text"
                fullWidth
                value={name}
                onChange={event => setName(event.target.value)}
            />
            <FormControl className={classes.formControl}>
                <InputLabel htmlFor="site">Työmaa</InputLabel>
                <Select
                    value={site}
                    onChange={event => setSite(event.target.value)}
                    inputProps={{
                        name: 'site',
                        id: 'site',
                    }}
                >
                    {props.sites && props.sites.map(site => {
                        return (<MenuItem value={site.id} key={site.id}>{site.name}</MenuItem>);
                    })}
                </Select>
            </FormControl>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="primary">
                Sulje
            </Button>
            <Button onClick={handleUpdate} color="primary">
                Tallenna
            </Button>
        </DialogActions>
    </Dialog>
});

const mapStateToProps = (state) => ({
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    sites: state.firestore.ordered.siteList
});

const mapDispatchToProps = (dispatch) => ({
    updateStatementType: (statement, id) => dispatch(updateStatementType(statement, id))
});
export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(UpdateStatement);
