export const inviteUser = (user) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();
        delete user.open;

        firestore.collection('invitations').add({
            ...user,
            company: getState().firebase.profile.company,
            createdAt: firestore.FieldValue.serverTimestamp()
        })
            .then(() => {
                dispatch({type: 'INVITATION_CREATED', user});
            })
            .catch((err) => {
                dispatch({type: 'CREATE_INVITATION_ERROR', err});
            });
    }
};
