import React from "react";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import Grid from "@material-ui/core/Grid";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import CreateMaster from "./CreateMaster";
import {connect} from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import {compose} from "redux";
import {firestoreConnect} from "react-redux-firebase";

const ListMasters = (props) => {

    const inviteRef = React.useRef();

    return <Paper className={props.classes.paper}>
        <CreateMaster ref={inviteRef}/>
        <List
            component="nav"
            subheader={<ListSubheader component="div"><Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
            >
                Työmaamestarit
                {props.profile && (props.profile.role === "admin" || props.profile.role === "worker") ?
                    <IconButton edge="end" aria-label="Kutsu työmaamestari" size={"small"}
                                onClick={() => inviteRef.current.handleOpen()}>
                        <FontAwesomeIcon icon="plus"/>
                    </IconButton> : null}
            </Grid></ListSubheader>}
        >
            {!props.masters ?
                <Box style={{display: 'flex', justifyContent: 'center', paddingBottom: 20, paddingTop: 20}}
                     width="100%"><CircularProgress/>
                </Box> : props.masters && props.masters.map((master, index) => {
                return <ListItem button key={index}>
                    <ListItemIcon>
                        <FontAwesomeIcon icon="user-hard-hat"/>
                    </ListItemIcon>
                    <ListItemText primary={master.name.first + " " + master.name.last}/>
                </ListItem>
            })}
        </List>
    </Paper>;

};

export const buildMasters = (users, profile) => {
    let built = [];
    users && Object.keys(users).map(user => {
        return built.push({
            ...users[user],
        });
    });

    return built;

};
const mapStateToProps = (state) => {
    const profile = state.firebase.profile;
    return {
        profile,
        masters: buildMasters(state.firestore.data.masters, state.firebase.profile)
    }

};
export default compose(connect(mapStateToProps, null, null, {forwardRef: true}), firestoreConnect(props => [{
    collection: 'users',
    where: [
        ['company', '==', props.profile.company],
        ['role', '==', 'master'],
    ],
    storeAs: 'masters'
}]))(ListMasters)
