const initState = {};
const siteReducer = (state = initState, action) => {
    switch (action.type) {
        case 'CREATE_SITE':
            return state;
        case 'CREATE_SITE_ERROR':
            console.error('Error happened while creating a new site', action.err);
            return state;
        default:
            return state;
    }
};

export default siteReducer;