import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {applyMiddleware, compose, createStore} from "redux";
import rootReducer from './store/reducers/rootReducer';
import {Provider} from 'react-redux';
import thunk from 'redux-thunk';
import {createFirestoreInstance, getFirestore, reduxFirestore} from "redux-firestore";
import {ReactReduxFirebaseProvider} from "react-redux-firebase";
import firebase from './config/fbConfig';

const getFirebase = () => firebase;

const store = createStore(rootReducer,
    compose(applyMiddleware(thunk.withExtraArgument({getFirebase, getFirestore})), reduxFirestore(firebase))
);
const rrfProps = {
    firebase,
    config: {
        ...firebase,
        userProfile: 'users',
        useFirestoreForProfile: true
    },
    createFirestoreInstance,
    dispatch: store.dispatch,
    attachAuthIsReady: true
};

const renderDom = () => {
    return ReactDOM.render(<Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
            <App/>
        </ReactReduxFirebaseProvider>
    </Provider>, document.getElementById('root'));
};
let unsubscribe = firebase.auth().onAuthStateChanged((user) => {
    renderDom();
    unsubscribe();
});


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
