import React from "react";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import Grid from "@material-ui/core/Grid";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import SimpleMenu from "../layout/SimpleMenu";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import CreateSite from "./CreateSite";
import {connect} from "react-redux";
import {firestoreConnect} from "react-redux-firebase";
import {compose} from 'redux';
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import {deleteSite} from "../../store/actions/siteActions";
import UpdateSite from "./UpdateSite";

const ListSites = (props) => {

    const createRef = React.useRef();
    const updateRef = React.useRef();
    const deleteFunc = (id) => {
        props.deleteSite(id);
    };
    return (<Paper className={props.classes.paper}>
        <CreateSite ref={createRef}/>
        <UpdateSite ref={updateRef}/>
        <List
            component="nav"
            subheader={<ListSubheader component="div"><Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
            >
                Työmaat
                {props.profile && (props.profile.role !== "accountant") ?
                    <IconButton edge="end" aria-label="Lisää työmaa" size={"small"}
                                onClick={() => createRef.current.handleOpen()}>
                        <FontAwesomeIcon icon="plus"/>
                    </IconButton> : null}
            </Grid></ListSubheader>}
        >
            {!props.sites ?
                <Box style={{display: 'flex', justifyContent: 'center', paddingBottom: 20, paddingTop: 20}}
                     width="100%"><CircularProgress/>
                </Box> : props.sites.map(site => {
                    return <ListItem button key={site.id}>
                        <ListItemIcon>
                            <FontAwesomeIcon icon="drafting-compass"/>
                        </ListItemIcon>
                        <ListItemText primary={site.name}/>
                        <ListItemSecondaryAction>
                            <SimpleMenu id={site.id} deleteFunc={() => deleteFunc(site.id)} updateRef={updateRef}
                                        item={site}/>
                        </ListItemSecondaryAction>
                    </ListItem>
                })}
        </List>
    </Paper>)
};

export const buildSites = (sites, profile) => {
    const siteArray = [];
    sites && sites.map(site => {
        return siteArray.push(site);
    });
    return siteArray;
};

const mapStateToProps = (state) => {

    const profile = state.firebase.profile;

    return {
        auth: state.firebase.auth,
        profile,
        sites: buildSites(state.firestore.ordered.siteList, state.firebase.profile)
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        deleteSite: (site) => dispatch(deleteSite(site))
    }
};


export default compose(connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true}), firestoreConnect((props) => {
    const query = [
        ['deleted', '==', false],
        ['company', '==', props.profile.company],
    ];

    if (props.profile.role === "master") query.push(['owners', 'array-contains', props.auth.uid]);
    return [{
        collection: 'sites',
        where: query,
        storeAs: 'siteList'
    }]
}))(ListSites)
