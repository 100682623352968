import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {BrowserRouter, Route} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import {connect} from "react-redux";
import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faCheck,
    faCommentAltDots,
    faComments,
    faDraftingCompass,
    faEllipsisV,
    faPen,
    faPlus,
    faSignOut,
    faTruck,
    faTruckContainer,
    faTruckPlow,
    faUser,
    faUserHardHat,
} from '@fortawesome/pro-solid-svg-icons'
import Navbar from "./components/layout/Navbar";
import ListSites from "./components/sites/ListSites";
import ListMachines from "./components/machines/ListMachines";
import ListMasters from "./components/masters/ListMasters";
import SignIn from "./components/auth/SignIn";
import SignUp from "./components/auth/SignUp";
import ListFills from "./components/fills/ListFills";
import Box from "@material-ui/core/Box";
import ListUsers from "./components/users/ListUsers";
import ResetPassword from "./components/auth/ResetPassword";
import ListStatements from "./components/statements/ListStatements";

library.add(faCommentAltDots, faTruck, faTruckContainer, faTruckPlow, faUserHardHat, faComments, faPen, faEllipsisV, faDraftingCompass, faPlus, faSignOut, faCheck, faUser);

const styles = theme => ({
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        boxShadow: "0 1rem 3rem rgba(0,0,0,.175)"
    },
});

const App = (props) => {
    return <BrowserRouter>
        {props.auth.uid ? <div>
                <Navbar/>
                <div style={{height: 500 + 'px', width: 100 + '%', background: '#5D4FFA'}}>
                </div>
                <Box mt={-50}>
                    <Container maxWidth={"xl"}>
                        {
                            props.profile.isLoaded ?
                                <Grid container spacing={4}>
                                    <Grid item xs={12} lg={9}>
                                        <ListFills classes={props.classes}/>
                                    </Grid>
                                    <Grid item xs={12} lg={3}>
                                        {props.profile.role === "worker" || props.profile.role === "master" || props.profile.role === "admin" ?
                                            <ListSites classes={props.classes}/> : null}
                                        {props.profile.role !== "accountant" ?
                                            <div>
                                                <ListMachines classes={props.classes}/>
                                                <ListStatements classes={props.classes}/>
                                            </div>
                                            : null}
                                        {props.profile.role === "worker" || props.profile.role === "admin" ?
                                            <div>
                                                <ListMasters classes={props.classes}/>
                                                <ListUsers classes={props.classes}/>
                                            </div>
                                            : null}
                                    </Grid>
                                </Grid>
                                : <p>Ladataan...</p>
                        }
                    </Container>
                </Box>
            </div> :
            <div>
                <Route exact path="/" component={SignIn}/>
                <Route exact path="/signin" component={SignIn}/>
                <Route exact path="/signup/:invitation" component={SignUp}/>
                <Route exact path="/reset-password" component={ResetPassword}/>
            </div>}
    </BrowserRouter>
};

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        modal: state.modal
    }
};

export default connect(mapStateToProps)(withStyles(styles, {withTheme: true})(App))
