export const createStatementType = (statement) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();
        firestore.collection('statement_types').add({
            ...statement,
            owner: getState().firebase.auth.uid,
            company: getState().firebase.profile.company,
            createdAt: firestore.FieldValue.serverTimestamp(),
            deleted: false
        })
            .then(() => {
                dispatch({type: 'CREATE_STATEMENT_TYPE', statement});
            })
            .catch((err) => {
                dispatch({type: 'CREATE_STATEMENT_TYPE_ERROR', err});
                console.log(err)
            });
    }
};

export const updateStatementType = (statement_type, id) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();
        firestore.collection('statement_types').doc(id).get().then(machineDoc => {
            if (!machineDoc.exists) return dispatch({
                type: 'UPDATE_STATEMENT_TYPE_ERROR',
                'err': {message: 'Selvitystyyppiä ei löytynyt!'}
            });
            firestore.collection('statement_types').doc(id).set({
                ...machineDoc.data(),
                ...statement_type
            }).then(() => {
                dispatch({type: 'UPDATE_STATEMENT_TYPE', statement_type});
            }).catch(err => {
                dispatch({type: 'UPDATE_STATEMENT_TYPE_ERROR', err});
            });
        }).catch(err => {
            dispatch({type: 'UPDATE_STATEMENT_TYPE_ERROR', err});
        });
    }
};

export const deleteStatementType = (id) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();
        firestore.collection('statement_types').doc(id).get().then(statement => {
            if (!statement.exists) return dispatch({type: 'DELETE_STATEMENT_TYPE_ERROR', 'err': {message: 'Selvitystyyppiä ei löytynyt!'}});
            firestore.collection('statement_types').doc(id).set({...statement.data(), deleted: true}).then(() => {
                dispatch({type: 'DELETE_STATEMENT_TYPE', statement});
            }).catch(err => {
                dispatch({type: 'DELETE_STATEMENT_TYPE_ERROR', err});
            });
        }).catch(err => {
            dispatch({type: 'DELETE_STATEMENT_TYPE_ERROR', err});
        });
    }
};