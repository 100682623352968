import React, {useImperativeHandle} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {connect} from "react-redux";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import {inviteUser} from "../../store/actions/userActions";
import Grid from "@material-ui/core/Grid";

const CreateUser = React.forwardRef((props, ref) => {

    const [open, setOpen] = React.useState(false);
    const [email, setEmail] = React.useState("");
    const [role, setRole] = React.useState("");
    const [name, setName] = React.useState({first: "", last: ""});


    useImperativeHandle(ref, () => ({
        handleOpen() {
            setOpen(true);
        }
    }));

    const handleClose = () => setOpen(false);
    const handleSave = () => {
        props.inviteUser({email, role, name});
        handleClose();
    };

    return <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={'sm'}
    >
        <DialogTitle id="form-dialog-title">Kutsu uusi käyttäjä</DialogTitle>
        <DialogContent>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="firstName"
                        variant="outlined"
                        required
                        fullWidth
                        id="firstName"
                        label="Etunimi"
                        autoFocus
                        value={name.first}
                        onChange={event => setName({
                            first: event.target.value,
                            last: name.last
                        })}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="lastName"
                        label="Sukunimi"
                        name="lastName"
                        value={name.last}
                        onChange={event => setName({
                            first: name.first,
                            last: event.target.value
                        })}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="email"
                        label="Sähköpostiosoite"
                        type="email"
                        value={email}
                        onChange={event => setEmail(event.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Valitse rooli</FormLabel>
                        <RadioGroup aria-label="position" name="position" value={role}
                                    onChange={event => setRole(event.target.value)}>
                            <FormControlLabel
                                value="admin"
                                control={<Radio color="primary"/>}
                                label="Pääkäyttäjä"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="worker"
                                control={<Radio color="primary"/>}
                                label="Työntekijä"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="accountant"
                                control={<Radio color="primary"/>}
                                label="Kirjanpitäjä"
                                labelPlacement="end"
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                {/*<Grid item xs={12}>
                    <FormControl style={{ width: '100%'}}>
                        <InputLabel htmlFor="select-masters">Työmaamestarit</InputLabel>
                        <Select
                            multiple
                            value={selectedMasters}
                            onChange={e => setSelectedMasters(e.target.value)}
                            input={<Input id="select-masters" fullWidth={true}/>}
                            renderValue={selected => (
                                <div className={classes.chips}>
                                    {selected.map(master => (
                                        <Chip key={master.id} label={master.name.first + " " + master.name.last} className={classes.chip} />
                                    ))}
                                </div>
                            )}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: 48 * 4.5 + 8,
                                        width: 250,
                                    }
                                }
                            }}
                        >
                            {props.masters && props.masters.map(master => (
                                <MenuItem key={master.id} value={master}>
                                    {master.name.first + " " + master.name.last}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>*/}
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="primary">
                Sulje
            </Button>
            <Button onClick={handleSave} color="primary">
                Kutsu
            </Button>
        </DialogActions>
    </Dialog>;
});

const mapStateToProps = state => ({});
const mapDispatchToProps = (dispatch) => {
    return {
        inviteUser: (user) => dispatch(inviteUser(user))
    }
};
export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(CreateUser);
