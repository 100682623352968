import React from "react";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import Grid from "@material-ui/core/Grid";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import CreateWorker from "./CreateUser";
import {connect} from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import {compose} from "redux";
import {firestoreConnect} from "react-redux-firebase";
import SimpleMenu from "../layout/SimpleMenu";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

const ListUsers = (props) => {

    const inviteRef = React.useRef();

    return <Paper className={props.classes.paper}>
        <CreateWorker ref={inviteRef}/>
        <List
            component="nav"
            subheader={<ListSubheader component="div"><Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
            >
                Käyttäjät
                {props.profile && (props.profile.role === "accountant" || props.profile.role === "admin") ?
                    <IconButton edge="end" aria-label="Kutsu käyttäjä" size={"small"}
                                onClick={() => inviteRef.current.handleOpen()}>
                        <FontAwesomeIcon icon="plus"/>
                    </IconButton> : null}
            </Grid></ListSubheader>}
        >
            {!props.workers ?
                <Box style={{display: 'flex', justifyContent: 'center', paddingBottom: 20, paddingTop: 20}}
                     width="100%"><CircularProgress/>
                </Box> : props.workers && props.workers.map((worker, index) => {
                return <ListItem button key={index}>
                    <ListItemIcon>
                        <FontAwesomeIcon icon="user"/>
                    </ListItemIcon>
                    <ListItemText primary={worker.name.first + " " + worker.name.last}/>

                    <ListItemSecondaryAction>
                        <SimpleMenu id={worker.id} deleteFunc={() => null}
                                    updateRef={null} item={worker}/>
                    </ListItemSecondaryAction>
                </ListItem>
            })}
        </List>
    </Paper>;
};

const buildData = (users) => {
    let built = [];
    users && Object.keys(users).map(user => {
        return built.push({
            ...users[user],
        });
    });
    return built;
};

const mapStateToProps = (state) => ({
    profile: state.firebase.profile,
    workers: buildData(state.firestore.data.workers)
});

export default compose(connect(mapStateToProps, null, null, {forwardRef: true}), firestoreConnect(props => [{
    collection: 'users',
    where: [
        ['role', '==', 'worker'],
        ['company', '==', props.profile.company]
    ],
    storeAs: 'workers'
}]))(ListUsers)
