import authReducer from './authReducer';
import fillsReducer from './fillsReducer';
import machineReducer from './machineReducer';
import masterReducer from "./masterReducer";
import siteReducer from "./siteReducer";
import modalReducer from "./modalReducer";

import {combineReducers} from "redux";
import {firestoreReducer} from "redux-firestore";
import {firebaseReducer} from "react-redux-firebase";
import statementReducer from "./statementReducer";

const rootReducer = combineReducers({
    auth: authReducer,
    fills: fillsReducer,
    machine: machineReducer,
    master: masterReducer,
    site: siteReducer,
    modal: modalReducer,
    statement_type: statementReducer,
    firestore: firestoreReducer,
    firebase: firebaseReducer,
});

export default rootReducer;