import React, {useImperativeHandle} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {createMachine} from "../../store/actions/machineActions";
import {connect} from "react-redux";

const CreateMachine = React.forwardRef((props, ref) => {
    const [open, setOpen] = React.useState(false);
    const [name, setName] = React.useState("");
    const [registerNumber, setRegisterNumber] = React.useState("");

    useImperativeHandle(ref, () => ({
        handleOpen() {
            setOpen(true);
        }
    }));

    const handleClose = () => setOpen(false);
    const handleSave = () => {
        props.createMachine({name, registerNumber});
        handleClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth={'sm'}
        >
            <DialogTitle id="form-dialog-title">Lisää uusi työkone</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Nimi"
                    type="text"
                    fullWidth
                    value={name}
                    onChange={event => setName(event.target.value)}
                />
                <TextField
                    margin="dense"
                    id="registerNumber"
                    label="Rekisterinumero"
                    type="text"
                    fullWidth
                    value={registerNumber}
                    onChange={event => setRegisterNumber(event.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Sulje
                </Button>
                <Button onClick={handleSave} color="primary">
                    Lisää
                </Button>
            </DialogActions>
        </Dialog>
    );
});


const mapDispatchToProps = (dispatch) => {
    return {
        createMachine: (machine) => dispatch(createMachine(machine))
    }
};
export default connect(null, mapDispatchToProps, null, {forwardRef: true})(CreateMachine);