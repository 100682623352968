import React, {useImperativeHandle, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {connect} from "react-redux";
import moment from "moment/moment";
import InputLabel from "@material-ui/core/InputLabel";
import Select from '@material-ui/core/Select';
import FormControl from "@material-ui/core/FormControl";
import {makeStyles} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import {createFill} from "../../store/actions/fillActions";
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import {populate} from "react-redux-firebase";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        marginTop: theme.spacing(1),
        width: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const ExportFill = React.forwardRef((props, ref) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const [machine, setMachine] = useState("all");
    const [site, setSite] = useState("all");
    const [user, setUser] = useState("all");
    const [master, setMaster] = useState("all");

    const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));

    const [includeStatus, setIncludeStatus] = useState(false);
    const handleClose = (e) => {
        e.preventDefault();
        setOpen(false);
    };

    useImperativeHandle(ref, () => ({

        handleOpen() {
            setOpen(true);
        }


    }));

    const handleExport = (e) => {
        e.preventDefault();
        let doc = new jsPDF();

        let fills = props.fills
            .filter(fill => machine === "all" || fill.machine === machine)
            .filter(fill => site === "all" || fill.site === site)
            .filter(fill => user === "all" || fill.user === user)
            .filter(fill => master === "all" || fill.master === master)
            .filter(fill => includeStatus || fill.checked)
            .filter(fill => (startDate === "" && endDate === "") || (fill.dateSeconds >= moment(startDate).valueOf() && fill.dateSeconds <= moment(endDate).valueOf()))
            .sort((a, b) => a.dateSeconds - b.dateSeconds).reverse()
        ;

        let columns = [
            {header: 'Päiväys', dataKey: 'date'},
            {header: 'Työkone', dataKey: 'machine'},
            {header: 'Selvitys', dataKey: 'statement'},
            {header: 'Työaika', dataKey: 'workHours'},
            {
                header: 'Työmaa',
                dataKey: 'site'
            },
            {header: 'Työntekijä', dataKey: 'user'},
            {header: 'Työmaamestari', dataKey: 'master'}];

        if (includeStatus) columns.push({header: 'Tarkistettu', dataKey: 'checkedString'});

        doc.autoTable({
            styles: {padding: 0},
            margin: {right: 0, left: 0, top: 0},
            columns: columns,
            body: fills,
            columnStyles: {
                date: {columnWidth: 25},
                machine: {columnWidth: 30},
                statement: {columnWidth: 30},
                workingHours: {columnWidth: 15},
                site: {columnWidth: 25},
                user: {columnWidth: 30},
                master: {columnWidth: 30},
                checkedString: {columnWidth: 23}
            }
        });
        doc.save('konelaput.pdf');
        handleClose(e);

    };

    let machines = [], sites = [], masters = [], users = [];
    return (
        <div>
            <Dialog
                open={open}
                onClose={() => handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Lataa PDF-tiedostona</DialogTitle>
                <DialogContent>
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="master">Työkone</InputLabel>
                        <Select
                            value={machine}
                            onChange={event => setMachine(event.target.value)}
                            inputProps={{
                                name: 'machine',
                                id: 'machine',
                            }}
                        >
                            <MenuItem value="all">Kaikki työkoneet</MenuItem>
                            {props.fills && props.fills.map(fill => {
                                if (machines.includes(fill.machine)) return null;
                                machines.push(fill.machine);
                                return (<MenuItem value={fill.machine} key={fill.machine}>{fill.machine}</MenuItem>);
                            })}
                        </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="master">Työmaa</InputLabel>
                        <Select
                            value={site}
                            onChange={event => setSite(event.target.value)}
                            inputProps={{
                                name: 'site',
                                id: 'site',
                            }}
                        >
                            <MenuItem value="all">Kaikki työmaat</MenuItem>
                            {props.fills && props.fills.map(fill => {
                                if (sites.includes(fill.site)) return null;
                                sites.push(fill.site);
                                return (<MenuItem value={fill.site} key={fill.site}>{fill.site}</MenuItem>);
                            })}
                        </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="user">Työntekijä</InputLabel>
                        <Select
                            value={user}
                            onChange={event => setUser(event.target.value)}
                            inputProps={{
                                name: 'user',
                                id: 'user',
                            }}
                        >
                            <MenuItem value="all">Kaikki työntekijät</MenuItem>
                            {props.fills && props.fills.map(fill => {
                                if (users.includes(fill.user)) return null;
                                users.push(fill.user);
                                return (<MenuItem value={fill.user} key={fill.user}>{fill.user}</MenuItem>);
                            })}
                        </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="master">Työmaamestari</InputLabel>
                        <Select
                            value={master}
                            onChange={event => setMaster(event.target.value)}
                            inputProps={{
                                name: 'master',
                                id: 'master',
                            }}
                        >
                            <MenuItem value="all">Kaikki työmaamestarit</MenuItem>
                            {props.fills && props.fills.map(fill => {
                                if (masters.includes(fill.master)) return null;
                                masters.push(fill.master);
                                return (<MenuItem value={fill.master} key={fill.master}>{fill.master}</MenuItem>);
                            })}
                        </Select>
                    </FormControl>

                    <TextField
                        className={classes.formControl}
                        margin="dense"
                        id="startDate"
                        label="Valitse ensimmäinen päivämäärä"
                        type="date"
                        fullWidth
                        value={startDate}
                        onChange={event => setStartDate(event.target.value)}
                    />
                    <TextField
                        className={classes.formControl}
                        margin="dense"
                        id="endDate"
                        label="Valitse viimeinen päivämäärä"
                        type="date"
                        fullWidth
                        value={endDate}
                        onChange={event => setEndDate(event.target.value)}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={includeStatus}
                                onChange={() => setIncludeStatus(!includeStatus)}
                                value={includeStatus}
                                color="primary"
                            />
                        }
                        label="Sisällytä tarkastamattomat täytöt (lisää uuden kentän tiedostoon)"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Sulje
                    </Button>
                    <Button onClick={handleExport} color="primary">
                        Lataa
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
});

const populates = [
    {child: 'machine', root: 'machines'},
    {child: 'site', root: 'sites'},
    {child: 'user', root: 'users'},
    {child: 'master', root: 'users'}
];

const buildData = (fills, state) => {
    let built = [];
    fills && Object.keys(fills).map(fill => {
        if (state.firebase.profile.role === "master" && fills[fill].master.id !== state.firebase.auth.uid) return null;
        if (state.firebase.profile.role === "worker" && fills[fill].user.id !== state.firebase.auth.uid) return null;
        return built.push({
            ...fills[fill],
            id: fill,
            machine: fills[fill].machine.name ? fills[fill].machine.name : "",
            site: fills[fill].site.name ? fills[fill].site.name : "",
            user: fills[fill].user.name ? fills[fill].user.name.first + " " + fills[fill].user.name.last : "",
            master: fills[fill].master.name ? fills[fill].master.name.first + " " + fills[fill].master.name.last : "",
            date: moment(fills[fill].date).format('DD.MM.YYYY'),
            dateSeconds: fills[fill].date,
            checkedString: fills[fill].checked ? 'Kyllä' : 'Ei',
            workHours: fills[fill].startTime && fills[fill].endTime ? fills[fill].startTime + " - " + fills[fill].endTime
                + " (" + fills[fill].hours + "h)" : fills[fill].hours + "h"
        });
    });
    return built;
};

const mapStateToProps = (state) => {
    return {
        sites: state.firestore.ordered.siteList,
        machines: state.firestore.ordered.machineList,
        masters: state.firestore.ordered.masters,
        fills: buildData(populate(state.firestore, 'fills', populates), state)
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        createFill: (fill) => dispatch(createFill(fill))
    }
};
export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(ExportFill);