import React, {useImperativeHandle, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {connect} from "react-redux";
import moment from "moment/moment";
import InputLabel from "@material-ui/core/InputLabel";
import Select from '@material-ui/core/Select';
import FormControl from "@material-ui/core/FormControl";
import {makeStyles, useMediaQuery, useTheme} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import {createFill} from "../../store/actions/fillActions";
import Downshift from "downshift";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import {deburr} from "lodash";
import Slide from "@material-ui/core/Slide";
import {buildStatementTypes} from "../statements/ListStatements";
import {buildSites} from "../sites/ListSites";

const renderInput = (inputProps) => {
    const {InputProps, classes, ref, ...other} = inputProps;

    return (
        <TextField
            InputProps={{
                inputRef: ref,
                classes: {
                    root: classes.inputRoot,
                    input: classes.inputInput,
                },
                ...InputProps,
            }}
            {...other}
        />
    );
};

const renderSuggestion = (suggestionProps) => {
    const {suggestion, index, itemProps, highlightedIndex, selectedItem} = suggestionProps;
    const isHighlighted = highlightedIndex === index;
    const isSelected = (selectedItem || '').indexOf(suggestion.name) > -1;

    return (
        <MenuItem
            {...itemProps}
            key={suggestion.id}
            selected={isHighlighted}
            component="div"
            style={{
                fontWeight: isSelected ? 500 : 400,
            }}
        >
            {suggestion.name}
        </MenuItem>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        marginTop: theme.spacing(1),
        width: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
        width: '100%'
    },
    container: {
        position: 'relative',
        width: '100%',
        marginTop: theme.spacing(1),
    },
    suggestionsContainerOpen: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
    },
    suggestion: {
        display: 'block',
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CreateFill = React.forwardRef((props, ref) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [machine, setMachine] = useState("");
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'));

    const [statement, setStatement] = useState("");
    const [master, setMaster] = useState("");
    const [site, setSite] = useState("");

    const [startTime, setStartTime] = useState("07:00");
    const [endTime, setEndTime] = useState("16:00");
    const [hours, setHours] = useState(0);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

    const handleClose = (e) => {
        e.preventDefault();
        setOpen(false);
        setMachine("");
        setDate(moment().format('YYYY-MM-DD'));
        setStatement("");
        setMaster("");
        setSite("");
        setStartTime("07:00");
        setEndTime("16:00");
        setHours(0);
    };

    useImperativeHandle(ref, () => ({
        handleOpen() {
            setOpen(true);
        }
    }));

    const handleSave = (e) => {
        e.preventDefault();
        props.createFill({
            machine,
            date: Number(moment(date).valueOf()),
            statement,
            master,
            site,
            startTime,
            endTime,
            hours
        });
        handleClose(e);
    };

    let filteredStatementTypes = props.statement_types && props.statement_types.filter(type => type.site === site);

    const getSuggestions = (value, {showEmpty = false} = {}) => {
        const inputValue = deburr(value.trim()).toLowerCase();
        const inputLength = inputValue.length;
        let count = 0;

        return inputLength === 0 && !showEmpty
            ? []
            : filteredStatementTypes.filter(suggestion => {
                const keep =
                    count < 5 && suggestion.name.slice(0, inputLength).toLowerCase() === inputValue;

                if (keep) {
                    count += 1;
                }

                return keep;
            });
    };

    return <Dialog
        open={open}
        onClose={() => handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={'sm'}
        fullScreen={fullScreen}
        TransitionComponent={Transition}
    >
        <DialogTitle id="form-dialog-title">Lisää uusi täyttö</DialogTitle>
        <DialogContent>
            <FormControl className={classes.formControl}>
                <InputLabel htmlFor="master">Työkone</InputLabel>
                <Select
                    value={machine}
                    onChange={event => setMachine(event.target.value)}
                    inputProps={{
                        name: 'machine',
                        id: 'machine',
                    }}
                >
                    {props.machines && props.machines.map(machine => {
                        return (<MenuItem value={machine.id} key={machine.id}>{machine.name}</MenuItem>);
                    })}
                </Select>
            </FormControl>
            <TextField
                className={classes.formControl}
                margin="dense"
                id="date"
                label="Päiväys"
                type="date"
                value={date}
                onChange={event => setDate(event.target.value)}
            />
            <FormControl className={classes.formControl}>
                <InputLabel htmlFor="site">Työmaa</InputLabel>
                <Select
                    value={site}
                    onChange={event => setSite(event.target.value)}
                    inputProps={{
                        name: 'site',
                        id: 'site',
                    }}
                >
                    {props.sites && props.sites.map(site => {
                        return <MenuItem value={site.id} key={site.id}>{site.name}</MenuItem>;
                    })}
                </Select>
            </FormControl>
            <Downshift
                id="statement"
                inputValue={statement}
                onChange={change => setStatement(change)}
                selectedItem={statement}
            >
                {({
                      getInputProps,
                      getItemProps,
                      getLabelProps,
                      isOpen,
                      inputValue: inputValue2,
                      highlightedIndex,
                  }) => {
                    const {onBlur, onChange, onFocus, ...inputProps} = getInputProps({
                        placeholder: 'Hae selvityspohjista...',
                    });

                    return <div className={classes.container}>
                        {renderInput({
                            fullWidth: true,
                            classes,
                            label: 'Selvitys',
                            InputLabelProps: getLabelProps(),
                            InputProps: {
                                startAdornment: item => (
                                    <Chip
                                        key={item.id}
                                        tabIndex={-1}
                                        label={item.name}
                                    />
                                ),
                                onBlur,
                                onChange: event => {
                                    setStatement(event.target.value);
                                    onChange(event);
                                },
                                onFocus,
                            },
                            inputProps,
                        })}

                        {isOpen ? (
                            <Paper className={classes.paper} style={{
                                position: 'absolute',
                                zIndex: 1,
                                left: 0,
                                right: 0,
                            }} square>
                                {getSuggestions(inputValue2).map((suggestion, index) =>
                                    renderSuggestion({
                                        suggestion,
                                        index,
                                        itemProps: getItemProps({item: suggestion.name}),
                                        highlightedIndex,
                                        selectedItem: suggestion.name,
                                    }),
                                )}
                            </Paper>
                        ) : null}
                    </div>;
                }}
            </Downshift>
            <TextField
                id="startTime"
                label="Aloitusaika"
                type="time"
                className={classes.formControl}
                inputProps={{
                    step: 300, // 5 min
                }}
                value={startTime}
                onChange={e => setStartTime(e.target.value)}
                fullWidth
                margin="dense"
            />
            <TextField
                id="startTime"
                label="Lopetusaika"
                type="time"
                className={classes.formControl}
                inputProps={{
                    step: 300, // 5 min
                }}
                value={endTime}
                onChange={e => setEndTime(e.target.value)}
                fullWidth
                margin="dense"
            />
            <TextField
                className={classes.formControl}
                margin="dense"
                id="hours"
                label="Tunnit"
                type="number"
                fullWidth
                value={hours}
                onChange={event => setHours(event.target.value)}
            />

        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="primary">
                Sulje
            </Button>
            <Button onClick={handleSave} color="primary">
                Lisää
            </Button>
        </DialogActions>
    </Dialog>;
});

export const buildFillMasters = (users, profile) => {
    let built = [];
    if (profile.role === "master") {
        users && users.map(user => {
            if (profile.id === user.id) {
                return built.push(user);
            }
            return null;
        });
        return built;
    }
    users && users.map(user => {
        return built.push(user);
    });
    return built;
};

const mapStateToProps = (state) => {
    const profile = state.firebase.profile;

    return {
        profile,
        sites: buildSites(state.firestore.ordered.siteList, profile),
        statement_types: buildStatementTypes(state.firestore.ordered.statement_types_list, profile),
        machines: state.firestore.ordered.machineList,
        masters: buildFillMasters(state.firestore.ordered.masters, profile)
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        createFill: (fill) => dispatch(createFill(fill))
    }
};
export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(CreateFill);
