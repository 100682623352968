const initState = {
    statement_types: []
};

const statementReducer = (state = initState, action) => {
    switch (action.type) {
        case 'CREATE_STATEMENT_TYPE':
            return state;
        case 'CREATE_STATEMENT_TYPE_ERROR':
            console.error('Error happened while creating a statement type', action.err);
            return state;
        default:
            return state;
    }
};

export default statementReducer;